import React, { useState, createRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { DropDown, CheckBox, TextEditableDetail, EditableDetail, DateEditableDetail } from 'components';
import { DetailBox } from './components/DetailBox';
import { SuspendReasonButton } from './components/SuspendReasonButton';
import { UploadInput } from 'components/UploadInput';
import { DeclarationTypes, DeclarationQuestionTypes } from '../../types';
import { useReviewDetailContext } from '../../Detail';
import { yesOrNoLabel } from '../../../../constants';
import { getDeclarationLinkMutation, updateQuotesDeclarationMutation } from 'services/quotations';
import './Detail.scss';
import { FutureDateMonthSelect } from './components/FutureDateMonth';
import { pdfIcon } from 'utils/assets';

interface DeclarationProps {
  title?: string;
  orderDeclarations: DeclarationTypes;
  declarationQuestions: DeclarationQuestionTypes[];
}

type ValuesType = string | number | readonly string[] | undefined | null | boolean;

const mapRadioValue = {
  "No": "Tidak",
  "Yes": "Ya"
};

export const Declaration = (props: DeclarationProps) => {
  const { title = 'Deklarasi', declarationQuestions, orderDeclarations } = props;
  const { quotationId, isAutoMoto, setOpen, quotation, refetchData } = useReviewDetailContext();
  const LP_BASE_URL = process.env.REACT_APP_LP_BASE_URL;
  const [values, setValues] = useState<ValuesType>(undefined);
  const [handleUpdateDeclarationMutation] = updateQuotesDeclarationMutation();
  const [handleGetDeclarationLink] = getDeclarationLinkMutation();
  const isCIV = isAutoMoto && quotation.declarationType === 'CIV';

  const updateDeclaration = async (field: string, directValue?: ValuesType) => {
    const fieldValue = directValue !== undefined ? directValue : values;

    let declaration: DeclarationTypes = orderDeclarations;
    declaration = {
      ...declaration,
      ...({ [field]: fieldValue }),
    };

    try {
      await handleUpdateDeclarationMutation({
        variables: {
          quotationId: quotationId,
          declaration: JSON.stringify(declaration),
        }
      });

      refetchData();
    } catch (error) {
      console.error('[Error] updateQuotesDeclaration : ', error);
    }
  };

  const getRTPLink = async (quotationId: string) => {
    try {
      const { data, errors } = await handleGetDeclarationLink({
        variables: {
          quotationId: quotationId
        }
      })

      const hash = data?.generateDeclarationLink?.declarationLinks?.hash;

      if (hash) {
        const composedRTPLink = `${LP_BASE_URL}/dropbox/?quotationId=${quotationId}&hash=${hash}`;
        window.open(composedRTPLink, '_blank');
        return;
      }

      throw errors;
    } catch (error) {
      console.error(error);
      toast.error('Gagal mendapatkan RTP link.');
    }
  }

  const onRemoveFile = (fieldName: string) => {
    updateDeclaration(fieldName, '')
  }

  const showPicture = (link: string | null | undefined) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  /* Value Views */
  const DateDeclarationValueView = (props: { d: DeclarationQuestionTypes, value: string }) => {
    let label = 'Tidak diisi.';

    if (props.value) {
      label = format(new Date(props.value), 'dd MMMM yyyy');
    } else if (!props.value && ['polis_inforced', 'inforceddate'].includes(props.d.field_name)) {
      label = 'Tanggal berlakunya polis akan sama dengan tanggal approve QA';
    }

    return (
      <span className='text-sm'>{label}</span>
    );
  }

  const AutoMotoCIVFieldView = () => {
    const { civImages } = quotation;
    const imagesUrl = civImages.map(img => img.url);

    const currentDeclaration: Record<string, string> = JSON.parse(quotation.declaration);
    const allocated = Object.values(currentDeclaration).filter((img) => imagesUrl.includes(img));

    return (
      <div className='flex flex-col gap-3'>
        <span className="font-medium">Hasil Inspeksi Kendaraan ({imagesUrl.length - allocated.length} foto Tersedia)</span>
        <button className='text-sm text-left text-sky-600 hover:underline mr-3' onClick={() => setOpen('sightcall')}>Klik di sini untuk mengalokasikan foto</button>
      </div>
    )
  }

  const AutoMotoRTPDeclarationFieldView = () => (
    <div className=' mt-5 flex flex-col gap-3'>
      <span className='font-medium'>Real Time Picture</span>
      <button className='text-sm text-left text-sky-600 hover:underline mr-3' onClick={() => getRTPLink(quotationId)}>Klik di sini untuk membuka halaman RTP</button>
    </div>
  );

  return (
    <DetailBox title={title} className='mb-7'>
      { isCIV && <AutoMotoCIVFieldView/> }
      { isAutoMoto &&  <AutoMotoRTPDeclarationFieldView /> }

      {
        declarationQuestions && declarationQuestions.length > 0 && declarationQuestions.map((d: DeclarationQuestionTypes, i: number) => {
          const quotationDeclarationValue: string = orderDeclarations[d.field_name] || '';
          
          let minDate: Date;
          let maxDate: Date;
          
          if (d.field_type === 'date') {
            minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 80);
            maxDate = new Date();
          } else if(d.field_type === 'future_date') {
            minDate = new Date();
            maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + 10);
          } else if(d.field_type === 'vehicle_delivery_date') {
            minDate = new Date();
            minDate.setDate(minDate.getDay() - 14);
            maxDate = new Date();
            maxDate.setDate(maxDate.getDay() + 14);
          } 

          return (
            <div className='flex flex-col gap-3' key={`${d.field_name}-${i}`}>
              <div className='mt-2 flex flex-col'>
                {(d.field_type === 'image' || d.field_type === 'file') && (
                  <EditableDetail
                    label={
                      <div>
                        {d.name}
                        {d.is_required && <span className='text-red'>*</span>}
                      </div>
                    }
                    value={quotationDeclarationValue}
                    renderValue={(value)=>{
                      if (!value) {
                        return (
                          <span className='flex items-center p-5 text-sm border border-slate-200 w-20 h-20 mt-2'>Tidak diisi.</span>
                        )
                      }

                      if (d.field_type === 'image') {
                        return (
                          <img alt={d.name} src={value} className='w-20 h-20 mt-2' />
                        )
                      }

                      return (
                        <a className='mt-2' href={value} download target="_blank" rel='noreferrer'>
                          <img alt={d.name} src={pdfIcon} className='w-20 h-20' />
                          <span className='text-sm underline text-sky-600'>Download Dokumen</span>
                        </a>
                      )
                    }}
                    onSubmit={({value})=>{
                      updateDeclaration(d.field_name, value);
                    }}
                    renderInput={({onChange, value}) => {
                      return (
                        <UploadInput
                          quotationValue={value}
                          fieldName={d.field_name}
                          inputType='image'
                          openedItem={d.field_name}
                          onRemoveFile={() => {
                            onChange('')
                          }}
                          onSaveImage={(imagePath) => {
                            onChange(imagePath[0]);
                          }}
                        />
                      )
                    }}
                  />
                )}
                {['text', 'numeric', 'alphanumeric'].includes(d.field_type) && (
                  <TextEditableDetail 
                    label={d.name}
                    value={quotationDeclarationValue}
                    onSubmit={({ value }) => {
                      updateDeclaration(d.field_name, value);
                    }}
                  />
                )}
                {['select', 'sinarmas_color'].includes(d.field_type) && (
                  <EditableDetail 
                    label={d.name}
                    value={quotationDeclarationValue}
                    renderValue={value => value}
                    renderInput={({ value }) => {
                      return (
                        <DropDown 
                          id={`${d.field_name}-select`}
                          value={value}
                          onChange={(thisOption: string | null) => setValues(thisOption as ValuesType)}
                          options={Object.keys(d.multiple_question).length > 0
                            ? Object.keys(d.multiple_question).map((data) => {
                              return { name: d.name, value: d.multiple_question[data], label: data };
                            })
                            : []}
                        />
                    )}}
                    onSubmit={({ value }) => {
                      updateDeclaration(d.field_name, value)
                    }}
                  />
                )}
                {d.field_type === 'radio' && (
                  <EditableDetail 
                    label={d.name}
                    value={quotationDeclarationValue}
                    renderValue={() => { 
                      return yesOrNoLabel[quotationDeclarationValue as keyof typeof yesOrNoLabel] || 'Tidak diisi.';
                    }}
                    renderInput={({ onChange, value }) => {
                      return (
                        <div className='mt-3 flex flex-row justify-start'>
                          {Object.keys(d.multiple_question).map((item) => (
                          <div className='flex' key={`declaration-radio-${d.field_name}-${item}`}>
                            <input
                              type="radio"
                              id={`declaration-radio-${d.field_name}-${item}`}
                              name="idCardType"
                              onChange={() => onChange(item)}
                              checked={value === item}
                            />
                            <label htmlFor="KTP" className="text-sm ml-2 mr-4">{mapRadioValue[item as keyof typeof mapRadioValue]}</label>
                          </div>
                        ))}
                        </div>
                      )
                    }}
                    onSubmit={({ value }) => {
                      updateDeclaration(d.field_name, value);
                    }}
                  />
                )}
                {d.field_type === 'checkbox' && (
                  <EditableDetail 
                    label={d.name}
                    value={quotationDeclarationValue}
                    renderInput={({ onChange, value }) => {
                      return (
                        <CheckBox 
                          key={d.field_name}
                          id={`declaration-${d.field_name}`}
                          checked={Boolean(value)}
                          label=''
                          value={value}
                          onChange={(res) => {
                            onChange(!res as any);
                            setValues(!res);
                          }}
                        />
                      )
                    }}
                    onSubmit={({ value }) => {
                      updateDeclaration(d.field_name, value);
                    }}
                  />
                )}
                {d.field_type === 'future_date_1_month' ? (
                  <EditableDetail 
                    label={d.name}
                    value={quotationDeclarationValue? new Date(quotationDeclarationValue) : null}
                    renderValue={() => {
                      return (
                        <DateDeclarationValueView d={d} value={quotationDeclarationValue} />
                      )
                    }}
                    renderInput={({value, onChange}) => {
                      return (
                        <FutureDateMonthSelect
                          value={value}
                          onChange={onChange}
                        />
                      )
                    }}
                    onSubmit={({value}) => {
                      updateDeclaration(d.field_name, value? value.toISOString(): null)
                    }}
                  />
                ) : (/date/i).test(d.field_type) && (
                  <DateEditableDetail
                    label={d.name}
                    value={quotationDeclarationValue? new Date(quotationDeclarationValue) : null}
                    onSubmit={({value})=>{
                      updateDeclaration(d.field_name, value.toISOString())
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                    renderValue={(value) => {
                      return (
                        <DateDeclarationValueView d={d} value={quotationDeclarationValue} />
                      )
                    }}
                  />
                )}
              </div>
              <SuspendReasonButton 
                suspendReasonGroup={d.field_name} 
                section='Deklarasi' 
                value={quotationDeclarationValue}
              />
            </div>
          )
        })
      }
    </DetailBox>
  );
};
